<template>
  <div>
    <h1>demo.vue</h1>
    <h2>{{arr}}</h2>
  </div>
</template>
<script>
import axios from "axios";
import util from '../commons/util';


export default {
  created() {
    let arr=["g51912030124592","g51912030121879"];
    axios.post('http://localhost:80/upload/test',{
      arr:arr
    })
  },
  data() {
    return {
      arr:[1,2,3]
    };
  },
  methods: {}
};
</script>
<style scoped>
</style>
